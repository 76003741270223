<template>
    <div class="pb-5">
        <v-title title="Vat profiles"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>Vat Profiles</h1>
                </div>

                <div class="col-3 text-right">
                    <mercur-button v-if="hasPermission('MerchantCentral/createVatProfile')" @click.native="triggerAdd" class="btn btn-primary text-uppercase">
                        + Add
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listVatProfiles')">
                <div>
                    <template v-if="selectedShop && selectedShop.applicationId !== '0'">
                        <div class="row mt-3">
                            <div class="col-6">
                                <quick-search :quickSearch.sync="filters.search" />
                            </div>
                            <div class="col-6">
                                <pagination :pagination="pagination" @change="changeAmountOfItems" />
                            </div>
                        </div>

                        <merchant-data-table :options="options" class="shadow" :url="url" :quickSearch="filters.search" ref="table" @paginationSet="updatePagination"></merchant-data-table>
                    </template>
                    <mercur-card class="mt-n4 mb-4" v-else>
                        <p>Select a shop in order to see vat profiles</p>
                    </mercur-card>
                </div>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>

            <div>
                <mercur-dialog :is-open.sync="removeDialog.isOpen">
                    <p>{{ removeDialog.content }}</p>
                    <template #footer>
                        <mercur-button class="btn" @click.native.prevent="removeDialog.isOpen = false">Cancel</mercur-button>
                        <mercur-button class="btn btn-primary" @click.native.prevent="confirmRemove">Confirm</mercur-button>
                    </template>
                </mercur-dialog>
            </div>

            <div>
                <mercur-dialog :is-open.sync="dialog.isOpen" width="800px">
                    <h2 class="font-weight-normal">{{ dialog.isEdit ? 'Edit' : 'Add' }} vat profile</h2>
                    <form-generator @submit="submit" @cancel="dialog = {}" :data="dialog.data" :fields="formFields" :url="dialog.url">
                        <template #default="slotData">
                            <div class="col-6">
                                <mercur-select v-model="slotData.form.companyProfileId">
                                    <template #label>Company profile</template>
                                    <option v-for="companyProfile in companyProfiles" :key="companyProfile.companyProfileId" :value="companyProfile.companyProfileId">{{ companyProfile.companyProfileLabel }}</option>
                                </mercur-select>
                            </div>
                            <div class="col-6">
                                <pretty-select
                                    v-if="filteredShops"
                                    placeholder="Applications"
                                    :options="filteredShops"
                                    :reduce="application => application.applicationId"
                                    label="applicationName"
                                    v-model="slotData.form.applications"
                                    :multiple="true"
                                    class="mb-4"
                                ></pretty-select>
                            </div>
                            <div class="col-6">
                                <template v-if="productVariantsForCountry">
                                    <pretty-select
                                        v-if="productVariantsForCountry"
                                        placeholder="Products"
                                        v-model="slotData.form.products"
                                        :multiple="true"
                                        :options="productVariantsForCountry.filter(({catalogProductId}) => !slotData.form.products || !slotData.form.products.includes(catalogProductId))"
                                        :reduce="getProductVariantId"
                                        :getOptionLabel="getProductName"
                                        label="productName"
                                        class="mb-4"
                                    ></pretty-select>
                                </template>
                            </div>
                        </template>
                    </form-generator>
                </mercur-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import QuickSearch from '@/components/elements/table/QuickSearch'
import FormGenerator from '@/components/utils/FormGenerator'
import { mapState } from 'vuex'
import PrettySelect from '../../components/utils/PrettySelect'
export default {
    name: 'VatProfiles',
    components: { PrettySelect, MerchantDataTable, Pagination, QuickSearch, FormGenerator },
    data () {
        return {
            url: CONFIG.API.ROUTES.VAT_CONFIG.VAT_PROFILES.LIST,
            pagination: {},
            amountOfDisplayedItems: 50,
            removeDialog: {},
            dialog: {},
            companyProfiles: null,
            productVariantsForCountry: null,
            formFields: [
                { key: 'vatProfileLabel', label: 'Label', required: true },
                { key: 'vatProducts', label: 'Products VAT', required: true, type: 'number', range: [0, 100], icon: 'fas fa-percent' },
                { key: 'vatShipping', label: 'Shipping VAT', required: true, type: 'number', range: [0, 100], icon: 'fas fa-percent' },
                { key: 'vatCountry', label: 'Country', required: true, country: true, onInput: this.handleCountryCodeChange },
                { key: 'vatNumber', label: 'Company VAT Number', maxLength: 20 },
                { key: 'vatCode', label: 'VAT Code', maxLength: 4 },
            ],
            options: {
                columns: {
                    'Label': { field: 'vatProfileLabel' },
                    'Mode': { field: 'vatMode' },
                    'Vat Number': { field: 'vatNumber' },
                    'Vat Code': { field: 'vatCode' },
                    'Country': { field: 'vatCountry' },
                    'Products Vat': { field: 'vatProducts', valueFormatter: (params) => `${(params.value * 100).toFixed(2)} %` },
                    'Shipping Vat': { field: 'vatShipping', valueFormatter: (params) => `${(params.value * 100).toFixed(2)} %` },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Duplicate',
                            onClick: ({ data }) => {
                                this.triggerDuplicate(data)
                            },
                            isHidden: () => !this.hasPermission('MerchantCentral/createVatProfile'),
                        },
                        {
                            text: 'Edit',
                            onClick: ({ data }) => {
                                this.triggerEdit(data)
                            },
                            isHidden: () => !this.hasPermission('MerchantCentral/updateVatProfile'),
                        },
                        {
                            text: 'Delete',
                            onClick: ({ data }) => {
                                this.triggerRemove(data)
                            },
                            isHidden: () => !this.hasPermission('MerchantCentral/removeVatProfile'),
                        },
                    ],
                },
                quickSearchColumns: ['vatCountry', 'companyProfileId', 'companyProfileLabel', 'companyEmail', 'companyCocNumber'],
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
            },
            filters: {
                search: '',
            },
        }
    },
    computed: {
        ...mapState('shop', ['shops']),
        filteredShops () {
            if (!this.shops || this.shops.length === 0) {
                return this.shops
            }

            return this.shops.filter(shop => this.selectedShop.parentOrganisationId === shop.organisationId || this.selectedShop.organisationId === shop.organisationId)
        },
    },
    methods: {
        getProductName (product) {
            if (typeof product === 'string') {
                const referenceProductVariant = this.productVariantsForCountry.find(variant => variant.catalogProductId === product)
                if (!referenceProductVariant) {
                    console.error(`Product reference cannot be found: `, product)
                    return product
                }
                return referenceProductVariant.productName
            }
            return product.productName
        },
        getProductVariantId (product) {
            if (typeof product === 'string') {
                return product
            }
            return product.catalogProductId
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        triggerRemove (data) {
            this.removeDialog = {
                isOpen: true,
                content: `Are you sure you want to remove this vat profile?`,
                data: data,
            }
        },
        confirmRemove () {
            const url = CONFIG.API.ROUTES.VAT_CONFIG.VAT_PROFILES.REMOVE.replace('{vatProfileId}', this.removeDialog.data.vatProfileId)
            this.post(url, {}, 'Vat profile removed').then(({ data }) => {
                this.removeDialog = {}
                this.$refs.table.refreshGrid()
            })
        },
        triggerDuplicate (data) {
            const clonedData = JSON.parse(JSON.stringify(data))
            clonedData.vatProfileLabel = `${clonedData.vatProfileLabel} (clone)`
            delete clonedData.vatProfileId
            const payload = {
                ...clonedData,
                vatProducts: (clonedData.vatProducts * 100).toFixed(2),
                vatShipping: (clonedData.vatShipping * 100).toFixed(2),
            }

            this.dialog = {
                isOpen: true,
                data: payload,
                url: CONFIG.API.ROUTES.VAT_CONFIG.VAT_PROFILES.CREATE,
            }
        },
        triggerEdit (data) {
            const payload = {
                ...data,
                vatProducts: (data.vatProducts * 100).toFixed(2),
                vatShipping: (data.vatShipping * 100).toFixed(2),
            }

            this.$set(this, 'dialog', {
                isOpen: true,
                isEdit: true,
                data: payload,
                url: CONFIG.API.ROUTES.VAT_CONFIG.VAT_PROFILES.UPDATE,
            })
        },
        submit () {
            this.dialog = {}
            this.$refs.table.refreshGrid()
            this.$root.$emit('notification:global', {
                message: 'VAT profile was added / updated',
            })
        },
        triggerAdd () {
            this.$set(this, 'dialog', {
                isOpen: true,
                data: {},
                url: CONFIG.API.ROUTES.VAT_CONFIG.VAT_PROFILES.CREATE,
            })
        },
        listCompanyProfiles () {
            this.post(CONFIG.API.ROUTES.VAT_CONFIG.COMPANY_PROFILES.LIST, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.companyProfiles = data
            })
        },
        getCatalogVariantsForCountry (countryCode) {
            this.$set(this, 'productVariantsForCountry', null)
            const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            requestPayload.request.filterModel['assignedCountries'] = {
                type: 'contains',
                filter: countryCode,
                filterType: 'text',
            }
            this.post(CONFIG.API.ROUTES.CATALOG.VARIANTS.OVERVIEW_IDS_AND_NAMES, requestPayload).then(({ data }) => {
                data.items.sort((productA, productB) => productA.productName.localeCompare(productB.productName))
                this.$set(this, 'productVariantsForCountry', data.items)
            })
        },
        listen () {
            this.listCompanyProfiles()
        },
        handleCountryCodeChange (countryCode) {
            if (!countryCode) {
                return
            }
            this.getCatalogVariantsForCountry(countryCode)
        },
    },
    watch: {
        'dialog.data.vatCountry' (newValue, oldValue) {
            if (newValue === oldValue) {
                return
            }
            this.handleCountryCodeChange(newValue)
        },
    },
    created () {
        if (this.selectedShop && this.selectedShop.applicationId !== '0') {
            this.listen()
        }
    },
    mounted () {
        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },
}

</script>
<style lang="scss" scoped>
    .vat-dialog {
        overflow: visible;
    }
</style>
